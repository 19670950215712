<template>
  <loading v-if="refreshload" backcolor="#ffa976"></loading>
  <div v-else style="height: 100vh">
    <div style="height: 100vh; display: flex; flex-direction: column">
      <div>
        <div style="width: 100%; background-color: #ffffff">
          <van-tabs style="width: 60%" v-model="activity" title-active-color="#000" @click="onCheck"
            :style="{ padding: '0 2% 0 2%' }">
            <van-tab name="1">
              <template #title>
                可使用 <span slot="title" style="font-size: 0.875rem">{{ available }}</span></template>
            </van-tab>
            <van-tab name="0">
              <template #title>
                不可用 <span slot="title" style="font-size: 0.875rem">{{ unAvailable }}</span>
              </template>
            </van-tab>
          </van-tabs>
        </div>
        <!-- 可使用可见页面 -->
        <div v-if="activity === '1'">
          <!-- 默认推荐等筛选项.. -->
          <div class="screenDiv">
            <van-button round hairline size="mini" type="success" @click="timeB">默认推荐</van-button>
            <van-button round style="margin-left: 3.067vw" hairline size="mini" type="success"
              @click="timeL">即将过期</van-button>
            <van-button round style="margin-left: 3.067vw" hairline size="mini" @click="numChange" type="success">
              点数
              <span>
                <img src="../../assets/images/paixu.png" class="screenIconImg" />
              </span>
            </van-button>
          </div>
        </div>
      </div>
      <!-- <div class="empt" v-if="cardData.length === 0">
      </div> -->
      <!-- 有卡券 -->
      <div class="wrapper" ref="wrapper">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <div class="content">
            <div v-if="activity === '1'" style="display: flex; width: 100%">
              <div class="addCard" @click="addCard"><van-icon name="plus" size="1.1rem" />绑定新卡</div>
              <div class="addCard2" @click="enterBuy">企业采购</div>
            </div>
            <!-- finished-text="没有更多了" -->
            <van-list v-model="loading" :finished="finished" @load="onLoad">
              <div v-for="(item, index) in cardData" :key="index" class="goods" :style="{
    backgroundImage: 'url(' + getback(item.themeImg, index) + ')',
    margin: '0.5rem 1rem 0.5rem 1rem',
    with: '100%',
    height: '12rem',
    borderRadius: '1rem',
    backgroundSize: 'cover'
  }">
                <div class="flex" style="flex-direction: column; justify-content: space-between; height: 100%"
                  @click="toDetail(item, index)">
                  <div style="display: flex; justify-content: space-between; width: 100%">
                    <div class="cardNo">卡号:<span style="font-size: 0.85rem;">{{ item.cardNo }}</span> </div>
                    <div v-if="activity == '1'" style="margin: 1.2rem 1.2rem 0 0; font-size: 0.85rem; color: #fdfafa">
                      {{ $moment(item.expireDate).format("YYYY.MM.DD") }} <span style="font-size:0.9rem ;">到期</span>
                    </div>
                  </div>

                  <div class="width-100 flex jcsb">
                    <div style="font-size: 1.1rem; color: #fdfafa; margin-left: 1.2rem">
                      <p style="color: white; margin-bottom: 0.2rem">
                        {{ getCardName(item.cardShowName ? item.cardShowName : item.cardName) }}
                      </p>
                    </div>

                    <div v-if="form.ticketType == 'E3'"
                      style="text-align: end; color: #ffffff; margin-right: 1.2rem; font-size: 1.1rem">
                      {{ item.cardRemainingPoint }}票
                    </div>
                    <div v-else style="text-align: end; color: #ffffff; margin-right: 1.2rem; font-size: 1.1rem">
                      {{ item.cardRemainingPoint }}点
                    </div>
                  </div>
                  <div class="width-100 flex jcsb" style="margin-bottom: 1.3rem; margin-right: 1.2rem;">
                    <div v-if="activity == '1' && form.ticketType == 'E4' && item.upgradeFlag == '1'" class="updateTag">
                      升级版
                    </div>
                    <div v-else></div>
                    <div class="flex">
                      <div v-if="activity == '1' && item.ifUpgrade == '1' && item.upgradeFlag == '0'"
                        :class="getColorType(item.themeImg, index) == 1 ? 'bottomTag' : 'bottomTag2'"
                        @click.stop="upgrade(item)">
                        升级
                      </div>
                      <span v-if="activity == '1'"
                        :class="getColorType(item.themeImg, index) == 1 ? 'bottomTag' : 'bottomTag2'"
                        @click.stop="toview(item)">去使用
                      </span>
                      <div v-else style="height: 1.2rem"></div>
                    </div>
                  </div>
                </div>
              </div>
            </van-list>
          </div>
        </van-pull-refresh>
      </div>
    </div>
    <!-- 底部 -->
    <foot-nav></foot-nav>
  </div>
</template>

<script>
import { Checkbox, CheckboxGroup, Toast } from "vant";
import { Dialog } from "vant";
import FootNav from "../../components/footnav/FootNav.vue";
// import  getCurrentCityName  from "@utils/getUserLocation"

export default {
  name: "CardViews",
  props: {},
  data() {
    return {
      showFoot: true,
      isLoading: false,
      loading: false,
      refreshload: true,
      finished: false,
      activity: "1",
      checkType: 1,
      imageback: require("@/assets/images/card_bg.svg"),
      imageback2: require("@/assets/images/card_bg2.svg"),
      imageErrorback: require("@/assets/images/card_error_bg.svg"),
      cardData: [],
      result: [],
      value: 1,
      cityList: [],
      address: "",
      checked: false,
      username: "",
      checkNum: 1, //1升序 2 降序
      form: {
        userId: "",
        size: 50,
        current: 1,
        usableType: "1",
        ticketType: "",
        numType: '',
        timType: '1',
      },
      unAvailable: 0, //不可用个数
      available: 0 //可用个数
    };
  },
  components: { FootNav, Checkbox, CheckboxGroup },
  methods: {
    // 默认推荐
    timeB() {
      this.form.timType = ''
      this.form.numType = ''
      this.onRefresh()
    },
    //即将过期
    timeL() {
      this.form.numType = ''
      this.form.timType = '1'
      this.onRefresh()
    },
    //点数
    numChange() {
      this.form.timType = ''
      if (this.form.numType !== '0') {
        this.form.numType = '0'
      } else {
        this.form.numType = '1'
      }
      this.onRefresh()
    },
    getCardName(str) {
      return str.length > 10 ? str.substring(0, 10) : str
    },
    toDetail(item, index) {
      localStorage.setItem('itemDetail', JSON.stringify(item))
      this.$router.push({
        path: '/UserDetail',
        query: { index: index, activity: this.activity }
      })
    },
    onCheck(name) {
      this.form.usableType = name;
      this.form.current = 1;
      this.cardData.length = 0;
      this.finished = false;
      this.getCard();
    },
    getBaiduAddress() {
      //在此调用api
      var geolocation = new BMapGL.Geolocation();
      geolocation.getCurrentPosition((a) => {
        console.log('a   ',a);
        //可以获取不同级别的位置信息，这里使用城市作为实例
        localStorage.setItem("latitude", a.latitude);
        localStorage.setItem("longitude", a.longitude);
        this.getCityList(a);
      });
    },
    getCityList(val) {
      this.$api
        .queryCityList(val.address.city)
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.address = res.data[0].provinceName.replace("市", "");
            localStorage.setItem("address", this.address);
            localStorage.setItem("cityCode", res.data[0].provinceId);
            this.$store.commit("cityCode", res.data[0].provinceId);
            this.$store.commit("setAddress", this.address);
          } else {
            this.$Toast.fail("定位失败");
          }
        })
        .catch((err) => {
        });
    },


    defaultRe() {
      if (this.checkType != 1) {
        this.checkType = 1;
      }
      this.$Toast.success("默认推荐");
    },
    willExpire() {
      if (this.checkType != 2) {
        this.checkType = 2;
      }
      this.$Toast.success("即将过期");
    },
    priceNum() {
      if (this.checkType != 3) {
        this.checkType = 3;
      } else {
        if (this.checkNum == 1) {
          this.checkNum = 2;
        } else {
          this.checkNum = 1;
        }
      }
      this.$Toast.success("点数");
    },
    // 刷新
    onRefresh() {
      this.form.current = 1;
      this.getCard();
    },
    onLoad() {

      if (this.cardData.length >= 1) {
        this.form.current = this.form.current + 1;
        this.getCard();
      }
    },

    getCard() {
      this.$api
        .cardList(this.form)
        .then((res) => {
          if (res.code == 1) {
            this.$Toast(res.msg);
            return
          }
          if (this.form.current == 1) {
            this.cardData = res.data.records;
          } else {
            this.cardData.push(...res.data.records);
          }
          if (this.form.current >= res.data.pages) {
            this.finished = true;
          }
          this.loading = false;
          this.setFalse()
        })
        .catch((err) => {
          this.finished = true;
          this.loading = false;
          this.isLoading = false;
          this.setFalse()
        });


    },
    setFalse() {
      let timer = setInterval(() => {
        this.refreshload = false
        clearInterval(timer);
      }, 200);
    },
    cardAvaAndUnAvaNum() {
      let forms = {
        userId: this.form.userId,
        ticketType: this.form.ticketType
      };
      this.$api
        .cardAvaAndUnAvaNum(forms)
        .then((res) => {
          if (res.code == 0) {
            this.unAvailable = res.data.unAvailable;
            this.available = res.data.available;
          }
        })
        .catch((err) => { });
    },
    getback(str, index) {
      if (this.activity == "1") {
        if (str == "" || str == undefined) {
          if (index % 2 == 0) {
            return this.imageback;
          } else return this.imageback2;
        } else {
          return str;
        }
      } else {
        return this.imageErrorback;
      }
    },
    getColorType(str, index) {
      if (str == "" || str == undefined) {
        if (index % 2 == 0) {
          return 1;
        } else return 2;
      } else {
        return 1;
      }
    },
    getCardType(str) {
      switch (str) {
        case "MOVIE_CARD":
          return "电影卡";
        default:
          return "通类";
      }
    },
    // 前去登陆
    goLogin() {
      this.$router.push("/Login");
    },
    // 去详情页
    toview(val) {
      if (this.activity == "0") {
        this.$Toast.fail("此卡不可用");
        return;
      }
      localStorage.setItem("cardId", val.cardId);
      localStorage.setItem("upgradeFlag", val.upgradeFlag);
      localStorage.setItem("bathId", val.id);

      if (val.ifCakeCardEnum == "CAKE_CARD") {
        this.$router.push({
          path: "/CakaHome"
        });
      } else if (val.ifCakeCardEnum == "MOVIE_CARD") {
        this.$router.push({
          path: "/MoviesHome"
        });
      } else {
        const data = {
          bathId: val.id,
          upgradeFlag: val.upgradeFlag
        }
        this.$api
          .isHomeShow(data)
          .then((res) => {
            if (res.data) {
              this.$router.push({
                path: "/HomeNew",
                query: { cardShowFlag: val.cardShowFlag }
              });
            } else {
              this.$router.push({
                path: '/GoodsList',
                query: { activityId: val.id }
              })
            }
          })
          .catch((err) => {
            this.$Toast.fail("此卡不可用");
          });
      }
    },
    enterBuy() {
      this.$router.push({
        path: "/EnterpriseBuy",
      });
    },
    addCard(val) {
      window.location.href = '/AddCard'
    },

    upgrade(val) {
      localStorage.setItem("cardItem", JSON.stringify(val));
      this.$router.push({
        path: "/UpdateCard",
        query: {
          ticketType: this.form.ticketType
        }
      });
    }
  },
  inject: ["reload"],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.reload();
    });
  },
  mounted() {
    localStorage.getItem("userInfo") ? (this.form.userId = JSON.parse(localStorage.getItem("userInfo")).id) : "";
    this.form.ticketType = this.$route.query.ticketType
      ? this.$route.query.ticketType
      : localStorage.getItem("entrCode");
    localStorage.setItem("entrCode", this.form.ticketType);
    var browserRule = /^.*((iPhone)|(iPad)|(Safari))+.*$/;
    if (browserRule.test(navigator.userAgent)) {
      window.onpageshow = function (event) {
        if (event.persisted) {
          window.location.reload()
        }
      };
    }
    this.getCard();
    this.cardAvaAndUnAvaNum();
    this.getBaiduAddress();
  },
  computed: {
  },
  watch: {}
};
</script>

<style lang="scss" scoped>
.empt {
  display: flex;
  flex-direction: column;
  height: 60%;
  width: 100%;
  min-height: 60%;
  align-items: center;
  justify-content: center;
}

.empt1 {
  transform: translateY(30%);
}

.bottomTag {
  font-size: 0.9rem;
  color: #d59a54;
  margin-right: 0.5rem;
  width: 4rem;
  border-radius: 1rem;
  text-align: center;
  padding: 0.2rem;
  background-color: #fff;
}

.bottomTag2 {
  font-size: 0.9rem;
  color: #6354ff;
  margin-right: 0.5rem;
  width: 4rem;
  border-radius: 1rem;
  text-align: center;
  padding: 0.2rem;
  background-color: #fff;
}

.updateTag {
  font-size: 1rem;
  color: white;
  margin-left: 1.5rem;
}

.wrapper {
  padding-bottom: 4.5rem;
  flex: 1;
  overflow-y: scroll;
}

::v-deep .van-tab {
  font-family: Helvetica Neue, Helvetica, sans-serif;
  font-size: 1.125rem;
  color: #696969;
  letter-spacing: 0;
  font-weight: 500;
}

::v-deep .van-tabs--line .van-tabs__wrap {
  height: 3.6rem;
}

::v-deep .van-tab--active {
  font-family: Helvetica Neue, Helvetica, sans-serif;
  font-size: 1.125rem;
  color: #383838;
  letter-spacing: 0;
  font-weight: 500;
}

::v-deep .van-tabs__line {
  position: absolute;
  bottom: 4vw;
  left: -0.4375rem;
  z-index: 1;
  width: 12.667vw;
  height: 1.1vw;
  background-color: #d33b2e;
  border-radius: 3px;
}

.orderhead {
  background-color: #f5f5f5;
}

.goods {}

.upgrade {
  border: 1px solid #fff;
  border-radius: 1rem;
  height: 1.3rem;
  padding: 0.2rem 0.7rem;
  color: #fff;
  margin: 1rem;
}

.title {
  font-size: 1.2rem;
}

.cardNo {
  color: #fff;
  margin: 1.2rem 0 0 1.2rem;
  font-size: 0.9rem;
}

.addCard {
  height: 3.5rem;
  line-height: 3.5rem;
  margin: 0.3rem 0.5rem 0 1rem;
  font-size: 1.1rem;
  border-radius: 0.5rem;
  background-image: url("../../assets/images/bind_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  color: #f5f5f5;
  text-align: center;
  padding: 0;
  width: 50%;
}

.addCard2 {
  height: 3.5rem;
  line-height: 3.5rem;
  margin: 0.3rem 1rem 0 0;
  font-size: 1.1rem;
  border-radius: 0.5rem;
  background-image: url("../../assets/images/bind_bg2.png");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  color: #f5f5f5;
  text-align: center;
  padding: 0;
  width: 50%;
}

// 筛选项
.screenDiv {
  height: 1.75rem;
  margin: 0.875rem 1rem 0.75rem 1rem;
}

::v-deep .van-button--mini {
  font-size: 3.667vw;
  background: #ffffff;
  padding: 3.067vw;

  // font-size: 0.875rem;
  color: #383838;
  letter-spacing: 0;
  font-weight: 400;
  height: 1.75rem;
}

::v-deep .van-hairline--surround:after {
  border: none;
}

.screenIconImg {
  height: 0.625rem;
  margin-top: 1px;
}
</style>
